/*=========================================================================================
  File Name: main.scss
  Description: Main scss file. Imports other scss partials from "vuesax" folder
==========================================================================================*/

@import "vuesax/variables";

@import "vuesax/layout";
@import "vuesax/typography";
@import "vuesax/misc";
@import "vuesax/extraComponents";
@import "vuesax/themes";
@import "vuesax/routerAnimations";
@import "vuesax/transitions";

@import "vuesax/customClasses";

// Vuesax fixes and enhancments
@import "vuesax/fixesVuesax";

// Tailwind Fixes
@import "vuesax/tailwindFix";

@import "~vue-multiselect/dist/vue-multiselect.min.css";

@import "@fortawesome/fontawesome-free/css/all.css";