/*
 ____       _        _
|  _ \ ___ | |_ __ _| |_ ___
| |_) / _ \| __/ _` | __/ _ \
|  _ < (_) | || (_| | ||  __/
|_| \_\___/ \__\__,_|\__\___|

 */
 @keyframes rotate {
    0% {
        -webkit-transform: translate3d(0, 0, 1, 0deg);
        transform:rotate3d(0, 0, 1, 0deg);
    }
    100% {
      -webkit-transform: translate3d(0, 0, 1, 359deg);
        transform:rotate3d(0, 0, 1, 359deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: translate3d(0, 0, 1, 0deg);
        transform:rotate3d(0, 0, 1, 0deg);
    }
    100% {
      -webkit-transform: translate3d(0, 0, 1, 359deg);
        transform:rotate3d(0, 0, 1, 359deg);
    }
}
.rotate {
    -webkit-animation: rotate 1s linear infinite;
    animation: rotate 1s linear infinite;
    transform-origin: 50% 50%;
}

