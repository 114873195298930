@import "main";
@import "./animate";

.vs-dialog {
    max-width: 66vw !important; //TODO: disable on mobile
}

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        background: black;
        color: white;
        border-radius: 4px;
        padding: 5px 10px 4px;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        margin: 5px;
        border-color: black;
        z-index: 1;
    }

    &[x-placement^="top"] {
        margin-bottom: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="bottom"] {
        margin-top: 5px;

        .tooltip-arrow {
            border-width: 0 5px 5px 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^="right"] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^="left"] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.popover {
        $color: #f9f9f9;

        .popover-inner {
            background: $color;
            color: black;
            padding: 24px;
            border-radius: 5px;
            box-shadow: 0 5px 30px rgba(black, 0.1);
        }

        .popover-arrow {
            border-color: $color;
        }
    }

    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
        transition:
            opacity 0.15s,
            visibility 0.15s;
    }

    &[aria-hidden="false"] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}

body {
    // SideBar and #content-area have independent scroll inside body
    overflow: hidden;
}

.vs-divider--text {
    padding-left: 0;
}

#app {
    .swal2-container {
        z-index: 52005;
    }
}

.page-list-search {
    margin-left: auto;
}

.page-list-search .vs-input--icon {
    top: 10px;
}

.action>span {
    display: block;

    >* {
        display: block;
        margin-left: 0.5rem;
    }
}

.vs-pagination--nav {
    justify-content: flex-end;
}

.text-nowrap {
    white-space: nowrap !important;
}

.vs-chip-warning.bg-chip-transparent {
    background: rgba(var(--vs-warning), 0.15) !important;
    color: rgba(var(--vs-warning), 1) !important;
}

.vs-chip-danger.bg-chip-transparent {
    background: rgba(var(--vs-danger), 0.15) !important;
    color: rgba(var(--vs-danger), 1) !important;
}

.vs-chip-primary.bg-chip-transparent {
    background: rgba(var(--vs-primary), 0.15) !important;
    color: rgba(var(--vs-primary), 1) !important;
}

.vdp-datepicker__calendar {
    z-index: 1000 !important;
}

// Alignement des bouton d'action de liste avec la pagination
.action .action-wrapper {
    display: flex;
    justify-content: flex-end !important;
}

.action-wrapper button {
    float: none;
    margin-right: 5px;
}

th.text-right .vs-table-text {
    justify-content: flex-end !important;
}

th.text-center .vs-table-text {
    justify-content: center !important;
}

a.link-myskilliz {
    text-decoration: underline;
}

.selected-lines {
    font-weight: 500;
}

.border-bottom {
    border-bottom: 1px solid #c3c3c3;
}

// Multiselect de la taille des champs Vuesax
.multiselect-sm {

    min-height: 33px;

    .multiselect__tags {
        min-height: 33px;
        padding: 5px 40px 0 8px;
    }

    .multiselect__tag {
        font-size: 11px;
        margin-bottom: 0;
    }

    .input-group-text + .form-multiselect .multiselect__tags {
        margin-left: -1px;
    }
    
    .multiselect__input, .multiselect__single {
        min-height: 18px;
        line-height: 18px;
        font-size: 11px;
    }
    
    .multiselect__placeholder {
        margin-bottom: 5px;
        padding-top: 0px;
        font-size: 11px;
        letter-spacing: normal;
    }

    .multiselect__select {
        height: 33px;
    }
}

.vs-sidebar--item {
    position: relative;
}

.menu-bullet {
    position: absolute;
    top: 4px;
    right: 4px;
}

.button-bullet {
    position: absolute;
    top: -5px;
    right: -5px;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.no-pointer-event {
    pointer-events: none;
    cursor: default;
}

.opaver {
    opacity: 0.5;
    color: #454545;
}

.opaver:hover {
    opacity: 1;
    cursor: pointer;
}

.vs-table--tbody-table .tr-values td {
    position: relative;
}

.cell-edit {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

// Fix - Multiselect dans un tableau Vuesax
.vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover,
.vs-table-primary .is-selected {
    transform: initial !important;
}

.drag-icon {
    width: 15px;
    height: 26px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGUlEQVQI12NgAIJz507/Z4AxVqxYAuEgAwD26QnAz+WLlgAAAABJRU5ErkJggg==);
    cursor: grab;
}
.dragging {
    background-color: #f3f3f3;
}