.vs-table--tbody {
    z-index: 0 !important;
}

.multiselect__single {
    font-size: 14px;
    color: grey;
}

.multiselect__content-wrapper {
    z-index: 450 !important;
    font-size: 10px;
}

.multiselect__input {
    font-size: 14px;
    /* padding: 0 !important; */
    margin-top: 2px !important;
    margin-bottom: 0px !important;
}

.multiselect__tag {
    background: rgb(204, 145, 172);
    font-size: 14px;
}

.multiselect__select:before {}

.multiselect__tags {
    border: 1px solid rgba(0, 0, 0, .2);
    min-height: 43px;
    max-height: 600px;

}

.multiselect__tag-icon.multiselect__option--highlight {
    background: rgb(204, 145, 172);
    color: #fff;

}

.multiselect__placeholder {
    color: grey;
    font-size: 14px;
    margin-left: 5px;
}

.multiselect__tag-icon::after {
    /* background: rgb(204, 145, 172); */
    color: #fff;
}

.multiselect__tag-icon:hover,
.multiselect__tag-icon:after:hover {
    background: rgb(204, 145, 172);

}

.multiselect__option--highlight {
    background: rgb(151, 47, 95);
    color: white;
}

.multiselect__option--selected {
    background: rgb(204, 145, 172);
    color: #636b6f;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: rgb(204, 145, 172);
}

.multiselect__option--group.multiselect__option--highlight {
    background: #636b6f;
}

.multiselect__option--group.multiselect__option--highlight::after {
    background: #636b6f;
}

.multiselect__option--group-selected {
    background: grey;
    color: black;
    font-weight: bold;
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: grey;
}
