///////////////////////////////////////////////////////////
// transition-group : list;
///////////////////////////////////////////////////////////
.list-leave-active {
    position: absolute;
}

.list-enter,
.list-leave-to{
    opacity: 0;
    transform: translateX(30px);
}


///////////////////////////////////////////////////////////
// transition-group : list-enter-up;
///////////////////////////////////////////////////////////
.list-enter-up-leave-active {
  transition: none !important;
}

.list-enter-up-enter {
  opacity: 0;
  transform: translateY(30px);
}
