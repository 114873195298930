.vue-form-wizard.md {
    .wizard-navigation {
        .wizard-progress-with-circle{
            top: 33px !important;
        }    

        .wizard-nav {
            li {
                .wizard-icon-circle{
                    border: 3px solid #cccccc;
                    width: 55px;
                    height: 55px;
    
                    .wizard-icon{
                        font-size: 1.5rem;
                    }
                }
                &.active {
                    .wizard-icon-circle{
                        &.checked {
                            background-color: rgba(var(--vs-primary),1);
                        }
                    }
                }
            }
            
            .stepTitle{
                color: #626262;
            }
        }
    }
}