// ALERT COMPONENT
.vs-alert {
    font-size: 1rem;

    code {
        background: #b5b5b5;
        color: $white;
    }
}

// Breadcrumb
.vs-breadcrumb--ol {
    a {

        &:focus,
        &:hover {
            color: $primary;
        }
    }

    .active {
        color: $primary;
    }
}

// TOOLTIP COMPONENT
.vs-tooltip {
    z-index: 52007;
    max-width: fit-content;
}


// CHIPS COMPONENT
.con-chips {
    .con-chips--input {
        border: none;
    }

    .con-vs-chip {
        margin: .75rem;
    }

    .con-chips--remove-all {
        right: 9px;
    }
}

.con-vs-chip {
    min-height: 26px;
    min-width: 26px;
    font-size: .8rem;
}

// COLLAPSE COMPONENT
.vs-collapse-item--header {
    font-size: 1.2rem;
    padding: 1rem;
}

.con-content--item {
    font-size: 1rem;
    padding: 1rem;
}

.vs-collapse.default,
.vs-collapse.shadow,
.vs-collapse.border,
.vs-collapse.margin {
    .open-item {
        .con-content--item {
            opacity: 1;
            padding: 1rem;
        }
    }
}

// Fixes reverse arrow in shadow collapse - Making PR
.vs-collapse.shadow .vs-collapse-item--icon-header {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.vs-collapse.shadow .open-item .vs-collapse-item--icon-header {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
}


// DIALOG COMPONENT
.con-vs-dialog {
    z-index: 52005;

    align-items: flex-start;

    .vs-dialog {
        margin: 3em auto;
    }

    @media screen and (max-width: 720px) {
        align-items: flex-start;

        .vs-dialog {
            margin: 3em auto;
        }
    }

    overflow: scroll;

    .vs-dialog-dark {
        position: fixed;
    }

    .vs-dialog {
        header {
            h3 {
                padding: 0.8rem;
            }

            span.after {
                width: 0;
            }
        }

        .vs-dialog-text {
            padding: 1rem;
            font-size: 1rem;
        }

        footer {
            padding: 1rem;
        }
    }
}


// DROPDOWN COMPONENT
.vs-con-dropdown {
    font-size: 1rem;
}

.con-vs-dropdown--menu {
    z-index: 42000;
}

// List COMPONENT
.vs-list--item {
    .list-titles {
        .vs-list--subtitle {
            font-size: .85rem;
        }
    }

    &:last-child {
        border-bottom: none;
    }
}

// POPUP COMPONENT
.con-vs-popup {
    z-index: 53000;

    .vs-popup--content {
        padding: 1rem;
        font-size: 1rem;
    }
}

// TABS COMPONENT
.vs-tabs--li button {
    font-size: 1rem;
}

// SELECT FORM ELEMENT COMPONENT
.con-select {
    .vs-select--input {
        padding: 10px;
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, .2);
    }
}

// SELECT OPTIONS FONT SIZE FIX
.vs-select--options {
    font-size: 1rem;

    span {
        color: $content-color;
        font-size: 1rem;
    }
}

// SWITCH FORM ELEMENT COMPONENT
.vs-switch--text {
    font-size: 0.7rem;
}

// INPUT FORM ELEMENT COMPONENT
.vs-con-input {
    .vs-inputx {
        padding: .7rem;
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, .2);
    }

    .vx-inputx:not(.input-rounded-full) {
        border-radius: 5px;
    }
}

.vs-input--input.normal {
    padding: .7rem;
    font-size: 1rem;
}

.vs-input--input.hasIcon {
    padding: 0.7rem 1rem 0.7rem 3rem;

    &.icon-after-input {
        padding: 0.7rem 3rem 0.7rem 1rem;
    }
}

.vs-input--placeholder.normal {
    padding: .6rem;
}

.vs-input--input.large {
    padding: 1rem;
    font-size: 1.2rem;

    &.hasIcon {
        padding: 1rem 0.8rem 1rem 2.5rem;
    }
}

.vs-input--placeholder.large {
    padding: 1rem;
}

.vs-input--input.small {
    padding: .4rem;
    font-size: .8rem;
}

.vs-input--placeholder.small {
    padding: .2rem .6rem;
    font-size: .8rem;
}

.vs-input--input.large~.vs-input--icon {
    top: 1rem;
    left: 0.8rem;
}

.vs-input--icon {
    top: 13px;

    &.feather {
        top: 10px;
    }
}

.vs-button--icon {
    z-index: 0;
}

.no-icon-border {
    .vs-input--icon {
        border: none;
    }
}


.vs-textarea {
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, .2);
    height: 100px;
}

.vs-con-textarea {
    border: none !important;
    margin-bottom: 0;

    &.focusx {
        border: none !important;

        .vs-textarea {
            border: 1px solid rgba(var(--vs-primary), 1);
        }
    }
}

.vs-checkbox-small .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
    transform: translateY(-3px);
}

.vs-col {
    padding: 0 15px;
}


.con-img-upload {
    overflow: hidden;
    padding: 0.6rem;

    .img-upload {
        margin: 15px;
    }
}

.view-upload {
    z-index: 52000;
}


// same height as input in form wizard
.select-large input.vs-select--input {
    padding: 11px;
}

// Change form-wizard-container font style
i.wizard-icon {
    font-style: inherit;
}

.stepTitle {
    margin-top: .5rem;
}

// font icon of feather
i.feather {
    font-weight: 100;
}

// Table
.vs-con-table {
    background: transparent;

    .vs-table--header {
        .vs-table--search {
            padding: 1rem 0;

            // align text in search input
            .vs-table--search-input {
                padding: 10px 28px;
                border: 1px solid rgba(0, 0, 0, .1);
                font-size: 0.9rem;
            }

            .vs-table--search-input:focus+i {
                left: 10px;
            }

            // align search icon in table
            i {
                left: 10px;
            }
        }

    }

    .vs-con-tbody {
        background: #f8f8f8;
        border: 2px solid #f8f8f8;
        width: 100%;
        overflow: auto;

        // Justify checkboxe center
        .con-vs-checkbox {
            justify-content: center !important;
        }

        .vs-table--tbody-table {
            font-size: 1rem;

            .tr-spacer {
                height: 2px;
            }

            .tr-table {
                .tr-expand {
                    td {
                        padding: 0;
                    }
                }

                td {
                    padding: 1rem;
                }
            }

            .vs-table--thead {
                th {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                .con-td-check {
                    background: transparent;
                    box-shadow: none;
                }
            }
        }
    }

    .vs-table--pagination {
        margin-top: 1rem;
    }
}

// Fixes checkbox gets in center
.con-vs-checkbox,
.con-vs-radio {
    justify-content: flex-start !important;
}

.con-vs-radio {
    display: inline-flex;
}

// Fixes input element dont get rounded borders
.input-rounded-full input {
    border-radius: 20px;
}

.v-select {
    .dropdown-toggle {
        .vs__actions {
            .clear {
                padding-top: 4px;
            }
        }
    }
}

.vs-button {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 1rem;

    &.vs-button-gradient {
        &:hover {
            box-shadow: none !important;
        }
    }

    &:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
        padding: .75rem 2rem;

        &.vs-button-border {
            padding: .679rem 2rem;
        }

    }

    &.small:not(.includeIconOnly) {
        padding: 0.5rem 1.5rem;
    }

    &.large:not(.includeIconOnly) {
        padding: 1rem 2.5rem;
    }

    &.large {
        font-size: 1.25rem;

        .vs-button--icon {
            font-size: 1.25rem;
        }
    }

    &.round {
        border-radius: 1.5rem;
    }

    &.includeIcon {
        float: left;
    }
}

.vs-popup--title,
.vs-notifications {
    h3 {
        margin-bottom: 0;
    }
}

.vs-notifications {
    z-index: 200000 !important;

    h3 {
        color: $white;
        font-weight: 600;
        font-size: $h5-font-size;
    }
}


// Navbar
.vs-navbar-null,
.vs-navbar-flat,
.vs-navbar-fund,
.vs-navbar-border,
.vs-navbar-shadow {
    .vs-navbar--item {
        a {
            &:hover {
                color: $primary !important;
            }
        }
    }
}

h3.vs-navbar--title {
    margin-bottom: 0;
}

.vs-navbar-gradient {
    .vs-navbar-item-primary {
        &.is-active-item {
            color: #fff !important;
        }
    }
}

// Dropdown color
.vs-con-dropdown {
    color: inherit;
}

// Dropdown Button padding
.dropdown-button-container {
    .vs-button {
        padding: .72rem 1.5rem !important;
    }

    .vs-button-line {
        padding: 9px 10px !important;
    }
}

// Loading container overflows
.con-vs-loading {
    // position: absolute !important;
}

// Alert title color
.vs-alert--title {
    color: inherit;
}



// search icon fix
.vs-input.input-rounded-full {
    .vs-input--input.hasIcon {
        padding: .8rem 1rem 0.8rem 3rem;
    }

    .input-span-placeholder {
        padding-left: 3rem;
        padding-top: .7rem;
    }

    .vs-icon {
        margin-top: .1rem;
        margin-left: 0.6rem;
        font-size: 1rem;
    }
}


// Position left tab component
.vs-tabs-position-left {
    .vs-tabs--li {
        padding: .35rem 0.3rem;
    }
}

// Pagination border radius
.vs-pagination--li.is-current {
    border-radius: 50%;

    .effect {
        border-radius: 50%;
    }
}

.vs-pagination--li.is-current {
    border-radius: 5rem;
}

.vs-pagination--ul {
    padding: 0;
}

// Select border color
.vs-select--options {
    border: 1px solid #eee;
    z-index: 530001;
}

// Input box iocn aliment
.vs-input--icon.feather {
    padding: .2rem .5rem 0rem .4rem;
}

.vs-input--input.hasIcon+.vs-input--placeholder {
    padding-left: 3rem;
}

// Search Box Text
.vs-input--input.large.hasIcon {
    padding: 1rem .8rem 1rem 3rem;
}

// Alert shadow and color issue
.con-vs-alert {
    box-shadow: none !important;
}

.vs-alert {
    font-weight: 500;
}


// Checkbox size: Small styles
.vs-checkbox-small .vs-checkbox--input:checked+.vs-checkbox .vs-icon {
    margin-top: 4px;
    margin-left: -1px;
}

.vs-checkbox-small input:checked+.vs-checkbox {
    background-color: rgba(var(--vs-primary), 1) !important;
}


.vs-dialog {
    footer {
        .vs-button:last-of-type {
            margin-left: .5rem !important;
            border-color: rgba(0, 0, 0, 0.2) !important;
        }
    }
}

// Feather icon in avatar component
.vs-avatar--text.feather {
    font-size: 1.3rem;
}

.vs-avatar--con-img {
    img {
        height: 100%;
    }
}

th .sort-th i {
    font-size: 1rem;

    &.down-sort:hover {
        transform: translateY(-2px);
    }

    &.up-sort:hover {
        transform: translateY(2px);
    }
}

// disable th selection on tables
.vs-table-text {
    user-select: none;
}

.con-vs-checkbox {
    z-index: 0;
}



.multiselect__tags {
    max-height: initial !important;
}
