@import '../_variables.scss';
  /////////////////////////////////////////////////////////
// NAVBAR
/////////////////////////////////////////////////////////
.vs-navbar {
    padding: .8rem 1rem;
    width: calc(100% - #{$sidebar-width} - 4.4rem);
    border-radius: .5rem;
    transition: all .5s;
    width: 100%;

    &.navbar-reduced {
        width: calc(100% - #{$reduced-sidebar-width} - 4.4rem);
    }

    &.navbar-full {
        width: calc(100% - 4.4rem);
    }
}



/////////////////////////////////////////////////////////
// CONTENT AREA
/////////////////////////////////////////////////////////
#content-area {
    // margin-left: $sidebar-width;
    height: 100vh;
    transition: width 0.5s;
    min-width: 0;
    width: calc(100vw - #{$reduced-sidebar-width});
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
        width: calc(100vw - #{$sidebar-width});
    }
    // transition: margin-left 0.5s;

    .content-wrapper{
        width: calc(100vw - #{$reduced-sidebar-width});
        flex: 1;
        @media screen and (min-width: 1200px) {
            width: calc(100vw - #{$sidebar-width});
        }
    }

    &.content-area-reduced {
        // margin-left: $reduced-sidebar-width;
    }

    &.content-area-full {
        margin-left: 0px;
    }

    .vx-navbar__search-input {
        input {
            border: none !important;
        }

        input:focus {
            box-shadow: none;
        }
    }
}

.router-view {
    padding: 1.3rem $spacer $spacer;
    height: 100%;
    &:before {
        content: '';
        width: 100%;
        height: 1.3rem;
        background-color: #f8f8f8;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }

    .router-content {
        margin-top: 5.5rem;
    }

    .content-area__heading {
        h2{
            color: #636363;
        }
    }

    &.vs-con-loading__container {
        overflow: unset;
    }
}
