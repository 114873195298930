/* ZOOM FADE */
.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: transform .35s, opacity .28s ease-in-out;
}
.zoom-fade-enter {
    transform: scale(0.97);
  opacity: 0;
}

.zoom-fade-leave-to {
    transform: scale(1.03);
    opacity: 0;
}

/* FADE TRNASITION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .28s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* PAGE SLIDE */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .35s, transform .4s;
}
.slide-fade-enter {
  opacity: 0;
  transform: translateX(-30%);
}

.slide-fade-leave-to{
    opacity: 0;
    transform: translateX(30%);
}

/* ZOOM OUT */
.zoom-out-enter-active, .zoom-out-leave-active {
  transition: opacity .35s ease-in-out, transform .45s ease-out;
}
.zoom-out-enter, .zoom-out-leave-to {
  opacity: 0;
  transform: scale(0);
}

/* UNFOLD SLIDE */
.fade-bottom-enter-active, .fade-bottom-leave-active {
  transition: opacity .3s, transform .35s;
}
.fade-bottom-enter {
  opacity: 0;
  transform: translateY(-8%);
}

.fade-bottom-leave-to{
    opacity: 0;
    transform: translateY(8%);
}